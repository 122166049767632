const EmailArchive = {
  id : null,
  subject: '',
  receiver: '',
  user_id: '',
  target_id: '',
  target_type: '',
  emailArchivable: {},
  created_at: null,
  cc: [],
};

export default EmailArchive
