<template>
  <div>
    <octo-table
      enable-export
      show-filters
      :action="endpoints.DATATABLES.emailArchive"
      :fields="fields"
      :filters="filters"
      :name="name"
      :show-id="false"
      :order-by-items="['created_at']"
      @onResetFilters="filters = {statuses: ['pending', 'ready']}"
      ref="emailArchiveDatatableRef"
    >
      <template v-slot:receiver="data">
        <div class="text-truncate text-uppercase">
          {{ getReceiverFullName(data.row) }}
        </div>
      </template>

      <template v-slot:email="data">
        <div class="small">
          {{ data.row | optional('receiver') }}
        </div>
      </template>

      <template v-slot:template="data">
        <div class="small text-uppercase">{{ $t('emails.templates.' + emailTemplates[data.row.email_template_id].name) }}</div>
      </template>

      <template v-slot:sender="data">
        <div class="small">
          {{ data.row.reply_to }}
        </div>
      </template>

      <template v-slot:date="data">
        {{ data.row.created_at | datetime }}
      </template>

      <template v-slot:status="data">
        <badge v-if="data.row.status" :type="data.row.status | emailStatusColor" class="text-uppercase">
          {{ $t('emails.status.' + data.row.status) }}
        </badge>
      </template>

      <template v-slot:actions="data">
        <div class="d-flex justify-content-end">

            <base-button class="mx-1" size="sm" icon link @click="openModal(data.row)">
              <octo-icon icon="edit"/>
            </base-button>

          <el-tooltip v-if="data.row.emailArchivable" :content="$t('common.registry')" :open-delay="300" placement="top">
            <base-button class="mx-1" size="sm" icon link @click="$router.push({name: 'registries.show', params: {id :  data.row.emailArchivable.registry_id}})">
              <octo-icon icon="user"/>
            </base-button>
          </el-tooltip>

          <base-button
            class="mx-1"
            type="danger"
            size="sm"
            icon
            link
            confirm-required
            @click="deleteEmail(data.row.id)">
            <octo-icon icon="wrong"/>
          </base-button>
        </div>
      </template>
      <template slot="custom-filters">
        <div class="col-12 col-md-6">
          <base-input :label="$t('common.template')">
            <el-select
              multiple
              filterable
              class="select-default text-uppercase"
              :placeholder="$t('common.type_to_search')"
              v-model="filters.templateIds"
            >
              <el-option
                v-for="template in emailTemplates"
                class="select-default text-uppercase"
                :value="template.id"
                :key="template.id"
                :label="$t('emails.templates.' + template.name)"
              >
              </el-option>
            </el-select>
          </base-input>
        </div>
      </template>
    </octo-table>
    <modal centered modalClasses="modal-lg"  :show.sync="showModal">
      <h5 slot="header" class="modal-title text-uppercase">{{ $t('common.details') }}</h5>
      <email-archive-details
        ref="emailArchiveDetails"
        editable
        :email-archive="currentEmailArchive"
        :key="modalKey"/>
      <template slot="footer">
        <base-button
          confirm-required
          link class="text-capitalize" @click="saveEmailArchive">
          {{ $t('common.save') }}
        </base-button>
      </template>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {DatePicker, MessageBox, Option, Select} from "element-ui";
import OctoTable from "@/components/octo-table/OctoTable";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import {endpoints} from "@/routes/endpoints";
import Modal from "@/components/Modal";
import EmailArchive from "@/models/emailArchive";
import EmailArchiveDetails from "@/pages/Email/components/EmailArchiveDetails";
import Badge from "@/components/Badge";

export default {
  name: "EmailScheduledDatatable",
  components: {
    Badge,
    EmailArchiveDetails,
    Modal,
    LabelThemeComponent,
    OctoIcon,
    OctoTable,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option
  },
  data() {
    return {
      endpoints: endpoints,
      name: "EmailScheduledDatatable",
      filters: {},
      showModal: false,
      modalKey: 0,
      currentEmailArchive: _.cloneDeep(EmailArchive),
      targetTypes: ['Lead', 'Deal', 'Student'],
      fields: [
        {prop: 'date', label: 'date', align: 'center', slot: true, width: 200, fixed: true},
        {prop: 'receiver', label: 'receiver', slot: true, width: 250, fixed: true},
        {prop: 'email', label: 'email', slot: true, width: 250,},
        {prop: 'sender', label: 'sender', slot: true, width: 250},
        {prop: 'template', label: 'template', slot: true, minWidth: 200},
        {prop: 'status', label: 'status', slot: true, width: 200},
        {prop: 'actions', label: 'actions', slot: true, align: 'center', fixed: 'right', width: 130},
      ],
    }
  },
  beforeMount() {
    this.filters = {
      ... this.getFilters(this.name) ,
      ... {statuses: ['pending', 'ready']}
    }
  },
  computed: {
    ...mapGetters({
      getFilters: 'common/datatableFilters',
      users: 'common/users',
      emailTemplates: 'common/emailTemplates',
    }),
  },
  methods: {
    pickerOptions() {
      return {firstDayOfWeek: 1};
    },

    openModal(emailArchive) {
      this.currentEmailArchive = this.$_.cloneDeep(emailArchive);
      this.modalKey++
      this.showModal = true;
    },

    getReceiverFullName(emailArchive) {

      switch (emailArchive.target_type) {
        case 'Deal':
        case 'Student':
        case 'Lead':
          const registry = emailArchive?.emailArchivable?.registry || {};
          return registry?.surname + ' ' + registry?.name;
        default:
          return '';
      }

    },

    deleteEmail: async function (index) {
      try {
        this.$fullLoading.show();
        await this.$api.delete(this.endpoints.EMAIL_ARCHIVE_DELETE.replace('{id}', index))
        this.$notify({type: 'success', message: this.$t('notifications.deleted_success')});
        await this.$refs.emailArchiveDatatableRef.getData();
      } catch (e) {
        this.$notify({type: 'danger', message: this.$t('notifications.mail_error')});
      } finally {
        this.$fullLoading.hide();
      }
    },

    async saveEmailArchive() {
      try {
        this.$fullLoading.show();
        const data = await this.$refs.emailArchiveDetails.getData();
        await this.$api.put(this.endpoints.EMAIL_ARCHIVE_UPDATE.replace('{id}', data.id), data)
        this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
        this.showModal = false;
        await this.$refs.emailArchiveDatatableRef.getData();
      } catch (e) {
        this.$notify({type: 'danger', message: this.$t('notifications.mail_error')});
      } finally {
        this.$fullLoading.hide();
      }


    }
  }
}
</script>

<style scoped>

</style>
