var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"emailArchiveForm"},[_c('ul',{staticClass:"list-group list-group-flush"},[_c('list-group-item-component',{attrs:{"label":_vm.$t('fields.date'),"value":_vm._f("datetime")(_vm.emailArchiveLocal.created_at)}}),_c('list-group-item-component',{attrs:{"label":_vm.$t('datatable.type')}},[_c('template',{slot:"value"},[(_vm.emailArchiveLocal.target_type)?_c('label-theme-component',[_vm._v(" "+_vm._s(_vm.$t('emails.targets.' + _vm.emailArchiveLocal.target_type))+" ["+_vm._s(_vm.emailArchiveLocal.target_id)+"] ")]):_vm._e()],1)],2),_c('list-group-item-component',{attrs:{"label":_vm.$t('common.template'),"value":_vm.$t('emails.templates.' + _vm.emailTemplates[_vm.emailArchiveLocal.email_template_id || 1].name)}}),_c('list-group-item-component',{attrs:{"label":_vm.$t('common.cc'),"value":_vm.ccList}}),_c('list-group-item-component',{attrs:{"label":_vm.$t('emails.receiver'),"label-width":160}},[_c('template',{slot:"value"},[(!_vm.editable)?_c('label-theme-component',[_vm._v(" "+_vm._s(_vm.emailArchiveLocal.receiver)+" ")]):_vm._e(),(_vm.editable)?_c('ValidationProvider',{staticClass:"flex-grow-1",attrs:{"name":"receiver","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{staticClass:"mb-0",class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"error":errors[0]},model:{value:(_vm.emailArchiveLocal.receiver),callback:function ($$v) {_vm.$set(_vm.emailArchiveLocal, "receiver", $$v)},expression:"emailArchiveLocal.receiver"}})]}}],null,false,2821661713)}):_vm._e()],1)],2),_c('list-group-item-component',{attrs:{"label":_vm.$t('emails.sender'),"value":_vm.emailArchiveLocal.reply_to,"label-width":160}},[_c('template',{slot:"value"},[(!_vm.editable)?_c('label-theme-component',[_vm._v(" "+_vm._s(_vm.emailArchiveLocal.reply_to)+" ")]):_vm._e(),(_vm.editable)?_c('ValidationProvider',{staticClass:"flex-grow-1",attrs:{"name":"reply_to","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{staticClass:"mb-0",class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"error":errors[0]},model:{value:(_vm.emailArchiveLocal.reply_to),callback:function ($$v) {_vm.$set(_vm.emailArchiveLocal, "reply_to", $$v)},expression:"emailArchiveLocal.reply_to"}})]}}],null,false,3379750686)}):_vm._e()],1)],2),_c('list-group-item-component',{attrs:{"label":_vm.$t('callcenter.subject'),"label-width":160}},[_c('template',{slot:"value"},[(!_vm.editable)?_c('label-theme-component',[_vm._v(" "+_vm._s(_vm.emailArchiveLocal.subject)+" ")]):_vm._e(),(_vm.editable)?_c('ValidationProvider',{staticClass:"flex-grow-1",attrs:{"name":"subject","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{staticClass:"mb-0",class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"error":errors[0]},model:{value:(_vm.emailArchiveLocal.subject),callback:function ($$v) {_vm.$set(_vm.emailArchiveLocal, "subject", $$v)},expression:"emailArchiveLocal.subject"}})]}}],null,false,2484254900)}):_vm._e()],1)],2),_c('li',{staticClass:"list-group-item p-2"},[_c('div',{staticClass:"text-uppercase small"},[_vm._v(_vm._s(_vm.$t('common.message')))]),(!_vm.editable)?_c('div',{staticClass:"max-height-300",domProps:{"innerHTML":_vm._s(_vm.emailArchiveLocal.message)}}):_vm._e(),(_vm.editable)?_c('octo-rich-text',{key:_vm.octoRichTextKey,staticClass:"mt-3",attrs:{"editor-height":280,"initial-value":_vm.emailArchiveLocal.message},on:{"onEditorUpdate":function (value) { return this$1.emailArchiveLocal.message = value; }}}):_vm._e()],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }