<template>
  <ValidationObserver ref="emailArchiveForm">
    <ul class="list-group list-group-flush">
      <list-group-item-component :label="$t('fields.date')" :value="emailArchiveLocal.created_at | datetime"/>
      <list-group-item-component :label="$t('datatable.type')">
        <template slot="value">
          <label-theme-component v-if="emailArchiveLocal.target_type">
            {{ $t('emails.targets.' + emailArchiveLocal.target_type) }} [{{ emailArchiveLocal.target_id }}]
          </label-theme-component>
        </template>
      </list-group-item-component>
      <list-group-item-component
        :label="$t('common.template')"
        :value="$t('emails.templates.' + emailTemplates[emailArchiveLocal.email_template_id || 1].name)"
      />
      <list-group-item-component :label="$t('common.cc')" :value="ccList"/>

      <list-group-item-component :label="$t('emails.receiver')" :label-width="160">
        <template slot="value">
          <label-theme-component v-if="!editable">
            {{ emailArchiveLocal.receiver }}
          </label-theme-component>
          <ValidationProvider
            v-if="editable"
            class="flex-grow-1"
            name="receiver"
            rules="required|email"
            v-slot="{ passed, failed, errors }"
          >
            <base-input
              class="mb-0"
              v-model="emailArchiveLocal.receiver"
              :error="errors[0]"
              :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
            />
          </ValidationProvider>
        </template>
      </list-group-item-component>
      <list-group-item-component :label="$t('emails.sender')" :value="emailArchiveLocal.reply_to" :label-width="160">
        <template slot="value">
          <label-theme-component v-if="!editable">
            {{ emailArchiveLocal.reply_to }}
          </label-theme-component>
          <ValidationProvider
            v-if="editable"
            class="flex-grow-1"
            name="reply_to"
            rules="required|email"
            v-slot="{ passed, failed, errors }"
          >
            <base-input
              class="mb-0"
              :error="errors[0]"
              :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
              v-model="emailArchiveLocal.reply_to"/>
          </ValidationProvider>
        </template>
      </list-group-item-component>
      <list-group-item-component :label="$t('callcenter.subject')" :label-width="160">
        <template slot="value">
          <label-theme-component v-if="!editable">
            {{ emailArchiveLocal.subject }}
          </label-theme-component>
          <ValidationProvider
            v-if="editable"
            class="flex-grow-1"
            name="subject"
            rules="required"
            v-slot="{ passed, failed, errors }"
          >
            <base-input
              :error="errors[0]"
              :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
              class="mb-0"
              v-model="emailArchiveLocal.subject"/>
          </ValidationProvider>
        </template>
      </list-group-item-component>


      <li class="list-group-item p-2">
        <div class="text-uppercase small">{{ $t('common.message') }}</div>
        <div v-if="!editable" v-html="emailArchiveLocal.message" class="max-height-300"></div>
        <octo-rich-text
          :key="octoRichTextKey"
          v-if="editable"
          class="mt-3"
          :editor-height="280"
          :initial-value="emailArchiveLocal.message"
          @onEditorUpdate="(value) => this.emailArchiveLocal.message = value"
        />
      </li>

    </ul>
  </ValidationObserver>
</template>

<script>
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import EmailArchive from "@/models/emailArchive";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import {mapGetters} from "vuex";
import _ from "lodash";
import OctoRichText from "@/components/octo-rich-text/OctoRichText";

export default {
  name: "EmailArchiveDetails",
  components: {OctoRichText, LabelThemeComponent, ListGroupItemComponent},
  data() {
    return {
      emailArchiveLocal: _.cloneDeep(EmailArchive),
      octoRichTextKey: 1
    }
  },
  props: {
    editable: {
      type: Boolean,
      default: false
    },
    emailArchive: {
      type: Object,
      default: () => _.cloneDeep(EmailArchive)
    }
  },
  computed: {
    ...mapGetters({
      users: "common/users",
      emailTemplates: "common/emailTemplates",
    }),

    ccList() {
      return !this.$_.isEmpty(this.emailArchiveLocal.cc)
        ? this.$_.join(this.emailArchiveLocal.cc, ', ')
        : '';
    },
  },
  mounted() {
    this.emailArchiveLocal = _.cloneDeep(this.emailArchive)
    this.octoRichTextKey++;
  },
  methods: {
    getData: function () {
      return new Promise((resolve, reject) => {
        this.$refs.emailArchiveForm.validate()
          .then((success) => {
            if (!success) {
              reject();
            } else {
              resolve(this.emailArchiveLocal);
            }
          })
          .catch((err) => {
            reject(err)
          });
      });
    }
  }
}
</script>

<style scoped>

</style>
