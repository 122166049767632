<template>
    <div @focusin.stop>
      <ckeditor
        :editor="editor"
        :config="editorConfig"
        v-model="editorData"
        @input="(data) => $emit('onEditorUpdate', data)"
      />
    </div>
</template>

<script>


import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from '@ckeditor/ckeditor5-vue2';

export default {
    name: "OctoRichText",
    components: {
      ckeditor: CKEditor.component
    },
    data() {
        return {
          editor: ClassicEditor,
          editorData: '',
          editorConfig: {
            toolbar: [ 'undo', 'redo', '|', 'bold', 'italic', '|', 'bulletedList', 'numberedList', 'blockQuote', '|' ],
            // The configuration of the editor.
          }
        }
    },
    props: {
        editorHeight: {
            type: Number,
            default: 120
        },
        initialValue: {
            type: [String, null],
            require: false
        },
        inline: {
            type: Boolean,
            default: false
        }
    },
    computed: {
    },
    mounted() {
      this.editorData = this.initialValue;
    },
    methods: {}
}
</script>

<style lang="scss">

</style>
