<template>
  <div class="row">
    <div class="col-md-12">
      <email-scheduled-datatable/>
    </div>
  </div>
</template>

<script>
import EmailScheduledDatatable from "@/pages/Email/datatables/EmailScheduledDatatable";

export default {
  name: "IndexScheduledArchivePage",
  components: {EmailScheduledDatatable},
}
</script>

<style scoped>

</style>
